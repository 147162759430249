import Avo from 'analytics/Avo';
import { INTEGRATION_PARTNERS } from 'config/types';
import useFlags from 'hooks/useFlags';
import { useQuery } from 'hooks/useQuery';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectPartner } from 'redux-stores/slices/authSlice';

const PARTNER_INCLUDES_WEBVIEW = [INTEGRATION_PARTNERS.CAREEM, INTEGRATION_PARTNERS.INSTASHOP, INTEGRATION_PARTNERS.INSTASHOP];

const useOrderStep = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const stepQuery = query.get('step');
  const { enableSelectServicesFlow } = useFlags();
  const partnerId = useSelector(selectPartner);
  const step = useMemo(() => (stepQuery ? parseInt(stepQuery, 10) : 0), [stepQuery]);
  const lastStepNo = enableSelectServicesFlow ? 1 : 2;

  useEffect(() => {
    Avo.viewOrderPage({
      step: step.toString(),
      experiment: enableSelectServicesFlow ? 'select_service' : 'default',
    });
  }, [enableSelectServicesFlow, step]);

  const setActiveStep = useCallback(
    (activeStep: number) => {
      navigate({
        search: `?step=${activeStep}`,
      });
    },
    [navigate]
  );

  const incrementStep = useCallback(() => {
    navigate({
      search: `?step=${step + 1}`,
    });
  }, [navigate, step]);

  const decrementStep = useCallback(() => {
    navigate({
      search: step === 1 ? `` : `?step=${step - 1}`,
    });
  }, [navigate, step]);

  const resetStep = useCallback(() => {
    // exclude Careem and InstaShop from goBack as there is a webview and has payment flow navigation events.
    if (PARTNER_INCLUDES_WEBVIEW.includes(partnerId)) {
      navigate({
        search: `?step=${0}`,
      });
    } else {
      // navigate back to the first step
      navigate(-(step ?? 1));
    }
  }, [navigate, step]);

  return {
    setActiveStep,
    incrementStep,
    decrementStep,
    resetStep,
    activeStep: step,
    isDelayUponNavigate: !PARTNER_INCLUDES_WEBVIEW.includes(partnerId),
    lastStepNo,
  };
};

export default useOrderStep;
