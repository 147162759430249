import { useQueryClient } from '@tanstack/react-query';
import { fetchDraftOrderAPI, postDraftOrderAPI } from 'api/order';
import type { Addresses } from 'api/types/addresses.types';
import { ServiceLine } from 'api/types/service.types';
import type { TimeSlots } from 'api/types/timeSlot.types';
import { type OrderState, restoreSnapshot } from 'pages/newOrder/newOrder.slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomer, setCustomerLocation } from 'redux-stores/slices/authSlice';

import { REACT_QUERY_KEYS } from '..';
import { useAllDayCustomerInformation } from '../user.queries';

export interface DraftOrderPayload {
  customerLocation: {
    lat: number;
    lng: number;
  };
  orderState: OrderState;
  storeAddresses: { addresses: Addresses.Address[] };
  customerAddresses: { addresses: Addresses.Address[] };
}

export interface IDraftOrderResponse {
  order: {
    orderData: DraftOrderPayload;
  };
}

function useCommonDraftOrderLogic() {
  const { getCustomerCards, getPricing } = useAllDayCustomerInformation();
  const queryClient = useQueryClient();
  const customer = useSelector(selectCustomer);
  const dispatch = useDispatch();

  const handleSuccess = async (data: IDraftOrderResponse) => {
    const {
      orderData: { customerLocation, orderState, storeAddresses, customerAddresses },
    } = data.order;
    dispatch(restoreSnapshot(orderState));
    dispatch(setCustomerLocation(customerLocation));
    if (orderState || customerLocation) {
      const { pickupTimeslot, dropoffAddressId } = orderState.currentOrder;
      const dropoffTimeSlots: TimeSlots.DropoffRootObject = {
        dropoffTimeslotsList: orderState.serviceLineTimeSlotData.laundry!!,
      };
      const { GET_ALL_CUSTOMER_ADDRESSES, GET_ALL_STORE_ADDRESSES } = REACT_QUERY_KEYS.addressKeys;
      queryClient.setQueryData([GET_ALL_STORE_ADDRESSES, customer?.id], storeAddresses);
      queryClient.setQueryData([GET_ALL_CUSTOMER_ADDRESSES, customer?.id], customerAddresses);
      queryClient.setQueryData([REACT_QUERY_KEYS.orderKeys.DRAFT_ORDER, customer?.id], {
        order: { customerLocation, orderState, storeAddresses, customerAddresses },
      });
      queryClient.setQueryData(
        [REACT_QUERY_KEYS.timeslotKeys.DROPOFF_TIMESLOT, pickupTimeslot?.date, dropoffAddressId, undefined, ServiceLine.LAUNDRY_SERVICE],
        dropoffTimeSlots
      );
      await Promise.all([getCustomerCards(), getPricing()]);
    }
  };

  return { customer, dispatch, handleSuccess, fetchDraftOrderAPI, postDraftOrderAPI };
}

export { useCommonDraftOrderLogic };
