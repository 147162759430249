import { BackIcon } from 'assets/icons';
import { Col, Container } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import styled from 'styled-components';

export const StyledContainer = styled(Container)`
  background-color: ${({ theme }) => theme.components.header.backgroundColor};
  flex-direction: row;
  margin-top: 25px;
  margin-bottom: 15px;
`;

export const StyledColRow = styled(Col)`
  gap: 12px;
`;

export const StyledBackIcon = styled(BackIcon)`
  width: 20px;
  height: 20px;
  g > g > g > g > path {
    fill: ${({ theme }) => theme.components.headerBackButton.color};
  }
`;

export const StyledImage = styled(Image)`
  width: 32px;
  height: 32px;
`;

export const StyledHeaderText = styled.h1`
  color: ${({ theme }) => theme.colors.deepPurple};
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0.4px;
  text-wrap: nowrap;
`;

export const StyledSpan = styled.span`
  width: 32px;
  height: 32px;
  background-color: #ff9f2b;
  position: absolute;
  left: 43px;
  border-radius: 50%;
`;

export const Styledh2 = styled.h2`
  color: ${({ theme }) => theme.colors.darkCharcoal};
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings:
    'liga' off,
    'clig' off;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px; /* 130.769% */
  letter-spacing: 0.4px;
`;
