import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { WASHMEN_CUSTOMER_API } from 'api/config';
import type { Preferences } from 'api/types/preferences.types';
import type { Customer } from 'api/types/user.types';
import { fetchCustomerAPI, fetchCustomerPreferencesAPI, updateLanguageAPI, updateUserAPI } from 'api/user';
import { useConfig } from 'context/app-config';
import i18n from 'i18n/config';
import type { LanguageCodes } from 'i18n/constants';
import { selectPreference } from 'pages/newOrder/newOrder.selectors';
import { setPreferences, setPreferencesForServiceLines } from 'pages/newOrder/newOrder.slice';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthToken, setUserDetails } from 'redux-stores/slices/authSlice';
import { setAppLanguage } from 'redux-stores/slices/uiSlice';
import type { AppDispatch } from 'redux-stores/store';
import MessagingService from 'wrappers/messaging';
import { notifyErrorObject, setUserProfile } from 'wrappers/reporting';

import { REACT_QUERY_KEYS } from '.';
import { usePrefetchPaymentCardsMutation } from './payment.queries';
import { usePrefetchPricing } from './pricing.queries';

export const useFetchCustomer = () => {
  const authToken = useSelector(selectAuthToken);
  const dispatch = useDispatch<AppDispatch>();

  return useQuery({
    queryKey: [REACT_QUERY_KEYS.userKeys.FETCH_CUSTOMER],
    queryFn: fetchCustomerAPI,
    enabled: Boolean(authToken),
    // @ts-ignore
    onSuccess: ({ user }: Customer.UserRootResponse) => {
      dispatch(setUserDetails(user));
    },
  });
};

export const useCustomerPreferences = () => {
  const dispatch = useDispatch<AppDispatch>();
  const authToken = useSelector(selectAuthToken);
  const config = useConfig();
  const preferences = useSelector(selectPreference);

  return useQuery({
    queryKey: [REACT_QUERY_KEYS.userKeys.FETCH_CUSTOMER_PREFERENCES],
    queryFn: fetchCustomerPreferencesAPI,
    enabled: Boolean(!preferences && authToken),
    select: useCallback((data: Preferences.RootObject) => data.customerPreferences, []),
    // @ts-ignore
    onSuccess: (data: Preferences.CustomerPreferences) => {
      dispatch(setPreferences(data));

      if (config?.modules.newOrder.dropoffs?.hasSupportForMultipleDropoffs) {
        dispatch(setPreferencesForServiceLines(data));
      }
    },
  });
};

export const useUpdateLanguage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [REACT_QUERY_KEYS.userKeys.UPDATE_LANGUAGE],
    mutationFn: ({ language }: { language: LanguageCodes }) => updateLanguageAPI({ language }),
    onSuccess: ({ user }, { language }) => {
      queryClient.setQueryData([REACT_QUERY_KEYS.userKeys.FETCH_CUSTOMER], user);
      dispatch(setAppLanguage(language));
      localStorage.setItem('language', language);

      const htmlTag = document.querySelector('html');
      const bodyTag = document.querySelector('body');

      WASHMEN_CUSTOMER_API.defaults.headers.common['Accept-Language'] = language;

      i18n.changeLanguage(language, (err) => {
        if (err) return notifyErrorObject(err);

        return null;
      });

      if (htmlTag && bodyTag) {
        htmlTag.lang = language;
        bodyTag.dir = i18n.dir();
      }
    },
  });
};

export function useUpdateUser({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: { user: Customer.Customer }) => void;
  onError?: (error: { message: string }) => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [REACT_QUERY_KEYS.userKeys.UPDATE_USER],
    mutationFn: (payload: Customer.UpdateUserInputs) => updateUserAPI(payload),
    onSuccess: (data) => {
      const { user } = data;
      queryClient.setQueryData([REACT_QUERY_KEYS.userKeys.FETCH_CUSTOMER], data.user);
      queryClient.setQueryData([REACT_QUERY_KEYS.userKeys.FETCH_CUSTOMER], data);
      // update messaging service details
      if (user.isIntercomIdentified) {
        MessagingService.getInstance().update({
          email: user.email,
          user_id: user.id,
          name: '  ', // `${user.firstName} ${user.lastName}`,
          phone: user.phone,
        });
      }

      // update error reporting
      setUserProfile({
        id: user.id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      });
      onSuccess?.(data);
    },
    onError,
  });
}

export const useAllDayCustomerInformation = () => {
  const { mutateAsync: getCustomerCards } = usePrefetchPaymentCardsMutation();
  const { mutateAsync: getPricing } = usePrefetchPricing();

  return { getCustomerCards, getPricing };
};
