import { ServiceLine } from 'api/types/service.types';

// Define a custom comparison arrow function
const priorityServiceLine = ServiceLine.LAUNDRY_SERVICE;
export const sortLaundryToFirstService = (a: ServiceLine, b: ServiceLine) => {
  if (a === priorityServiceLine && b !== priorityServiceLine) {
    return -1; // a comes before b
  }
  if (a !== priorityServiceLine && b === priorityServiceLine) {
    return 1; // b comes before a
  }
  // Otherwise, sort alphabetically
  return a.localeCompare(b);
};
