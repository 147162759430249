import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Avo from 'analytics/Avo';
import {
  addPromoCodeAPI,
  cancelActiveOrderAPI,
  createNewOrderAPI,
  fetchOrderDetailsAPI,
  rateOrderAPI,
  updateActiveOrderAPI,
  updateActiveOrderDropoffAPI,
  updateActiveOrderPickupAPI,
} from 'api/order';
import type { Order } from 'api/types/order.types';
import type { Customer } from 'api/types/user.types';
import { fetchActivePastOrdersAPI } from 'api/user';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomer, setActiveOrderIds, setOrderToRate } from 'redux-stores/slices/authSlice';
import { trackEvent } from 'wrappers/reporting';

import { REACT_QUERY_KEYS } from '.';
import { type DraftOrderPayload, useCommonDraftOrderLogic } from './common/order.common';

export function usePastActiveOrders() {
  const customer = useSelector(selectCustomer);
  return useQuery({
    queryKey: [REACT_QUERY_KEYS.orderKeys.PAST_ACTIVE_ORDERS],
    queryFn: fetchActivePastOrdersAPI,
    // only fetch if user is logged in
    enabled: Boolean(customer?.id),
    select: useCallback((data: Order.ActivePastOrdersRootObject) => data.orders ?? [], []),
  });
}

export const useUpdateCouponCode = () =>
  useMutation({
    mutationFn: (promocode: string) => addPromoCodeAPI(promocode),
    onSuccess: (_, promocode) => {
      Avo.addPromocode({
        promocode,
      });
    },
  });

export const usePlaceOrderCore = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (newOrder: Order.NewOrder) => createNewOrderAPI(newOrder),
    onSuccess: (data) => {
      const { updatedUser, newOrder } = data;
      trackEvent('OrderPlaced', newOrder);

      queryClient.setQueryData([REACT_QUERY_KEYS.orderKeys.FETCH_ORDER_DETAILS, newOrder.id], newOrder);
      queryClient.setQueryData([REACT_QUERY_KEYS.userKeys.FETCH_CUSTOMER], data.updatedUser);
      // refetching active orders
      queryClient.refetchQueries([REACT_QUERY_KEYS.orderKeys.PAST_ACTIVE_ORDERS]);

      if (updatedUser) {
        dispatch(setActiveOrderIds(updatedUser.activeOrderIds));
      }
    },
    onError: (error, newOrder) => {
      trackEvent('OrderNotPlaced', newOrder);
    },
    onMutate: (newOrder: Order.NewOrder) => {
      trackEvent('OrderSubmitted', newOrder);
    },
  });
};

export const useOrderDetails = (id?: string, initialData?: Order.Order) =>
  useQuery({
    queryKey: [REACT_QUERY_KEYS.orderKeys.FETCH_ORDER_DETAILS, id],
    queryFn: () => fetchOrderDetailsAPI(id!!),
    enabled: !!id,
    initialData,
  });

export const useUpdateCurrentOrder = (orderId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Order.UpdateOrder) => updateActiveOrderAPI(orderId, data),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.refetchQueries([REACT_QUERY_KEYS.orderKeys.FETCH_ORDER_DETAILS, orderId]);
      }, 3000);
    },
  });
};

export const useUpdatePickup = ({
  orderId,
  onSuccess,
  onError,
}: {
  orderId: string;
  onSuccess?: (data: { order: Order.Order }) => void;
  onError?: (error: unknown) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Order.UpdatePickup) => updateActiveOrderPickupAPI(orderId, data),
    onSuccess: (data) => {
      queryClient.setQueryData([REACT_QUERY_KEYS.orderKeys.FETCH_ORDER_DETAILS, orderId], data.order);
      onSuccess?.(data);
    },
    onError,
  });
};

export const useUpdateDropoff = ({
  orderId,
  onSuccess,
  onError,
}: {
  orderId: string;
  onSuccess?: (data: { order: Order.Order }) => void;
  onError?: (error: unknown) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Order.UpdateDropoff) => updateActiveOrderDropoffAPI(orderId, data),
    onSuccess: (data) => {
      setTimeout(() => {
        queryClient.refetchQueries([REACT_QUERY_KEYS.orderKeys.FETCH_ORDER_DETAILS, orderId]);
      }, 2000);
      onSuccess?.(data);
    },
    onError,
  });
};

export function useCancelActiveOrder(onSuccess?: (data: { updatedUser: Customer.Customer }) => void) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(({ orderId, reason }: { orderId: string; reason: string }) => cancelActiveOrderAPI(orderId, reason), {
    onSuccess: (response, variables) => {
      const { updatedUser } = response;
      queryClient.invalidateQueries([REACT_QUERY_KEYS.orderKeys.FETCH_ORDER_DETAILS, variables.orderId]);
      queryClient.refetchQueries([REACT_QUERY_KEYS.orderKeys.ALL_ORDERS_PAGINATED]);
      queryClient.setQueryData([REACT_QUERY_KEYS.userKeys.FETCH_CUSTOMER], response.updatedUser);
      // refetching active orders
      queryClient.refetchQueries([REACT_QUERY_KEYS.orderKeys.PAST_ACTIVE_ORDERS]);

      // update active order ids
      if (updatedUser) {
        dispatch(setActiveOrderIds(updatedUser.activeOrderIds));
      }
      onSuccess?.(response);
    },
  });
}

export function useRateOrder() {
  const dispatch = useDispatch();
  return useMutation((config: { orderId: string; data: Order.Rating }) => rateOrderAPI(config.orderId, config.data), {
    onSuccess: () => {
      // clear the order to rate so sheet won't appear again.
      dispatch(setOrderToRate(undefined));
    },
  });
}

export function useDraftOrderMutation({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void } = {}) {
  const { customer, postDraftOrderAPI } = useCommonDraftOrderLogic();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (props: DraftOrderPayload) => postDraftOrderAPI(props),
    onSuccess: (data) => {
      onSuccess?.();
      queryClient.setQueryData([REACT_QUERY_KEYS.orderKeys.DRAFT_ORDER, customer?.id], data);
    },
    onError,
  });
}

export function useDraftOrderQuery(enabled = true) {
  const { customer, handleSuccess, fetchDraftOrderAPI } = useCommonDraftOrderLogic();

  const ONE_HOUR_IN_MS = 60 * 60 * 1000; // 1 hour in milliseconds

  return useQuery({
    queryKey: [REACT_QUERY_KEYS.orderKeys.DRAFT_ORDER, customer?.id],
    queryFn: fetchDraftOrderAPI,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    cacheTime: ONE_HOUR_IN_MS, // 1 hour in milliseconds
    onSuccess: (data) => handleSuccess(data),
    retryOnMount: false,
    retry: false,
    enabled: enabled && Boolean(customer?.id),
  });
}

export function useDraftOrderQueryMutation() {
  const { customer, handleSuccess, fetchDraftOrderAPI } = useCommonDraftOrderLogic();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: fetchDraftOrderAPI,
    onSuccess: (data) => {
      queryClient.setQueryData([REACT_QUERY_KEYS.orderKeys.DRAFT_ORDER, customer?.id], data);
      handleSuccess(data);
    },
    retry: false,
  });
}
