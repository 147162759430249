import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Avo from 'analytics/Avo';
import {
  addFailedOrderPaymentCardAPI,
  addPaymentCardAPI,
  deletePaymentCardAPI,
  fetchCustomerPaymentCardsAPI,
  updateDefaultPaymentAPI,
} from 'api/payment';
import type { Cards } from 'api/types/cards.types';
import type { Order } from 'api/types/order.types';
import type { Customer } from 'api/types/user.types';
import { useConfig } from 'context/app-config';
import { setPaymentCardInfo } from 'pages/newOrder/newOrder.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectCustomer } from 'redux-stores/slices/authSlice';
import type { AppDispatch } from 'redux-stores/store';

import { REACT_QUERY_KEYS } from '.';

export function usePaymentCards() {
  const dispatch = useDispatch<AppDispatch>();
  const customer = useSelector(selectCustomer);
  return useQuery<Cards.RootObject>([REACT_QUERY_KEYS.paymentKeys.PAYMENT_CARDS], fetchCustomerPaymentCardsAPI, {
    onSuccess: (data) => {
      dispatch(
        setPaymentCardInfo({
          cards: data.cards,
          lastSelectedCardId: customer?.lastSelectedCardId,
          lastSelectedPaymentMethod: customer?.lastSelectedPaymentMethod,
        })
      );
    },
    retry: (failureCount) => failureCount < 4,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}

export function usePrefetchPaymentCardsMutation() {
  const queryClient = useQueryClient();

  return useMutation(fetchCustomerPaymentCardsAPI, {
    onSuccess: (data) => {
      // Update the payment cards in the cache
      queryClient.setQueryData([REACT_QUERY_KEYS.paymentKeys.PAYMENT_CARDS], data);
    },
  });
}

export function useAddPaymentCard({ onSuccess, onError }: { onSuccess?: (data: Cards.PostAPIResponse) => void; onError?: (error: unknown) => void }) {
  const queryClient = useQueryClient();
  const location = useLocation();
  return useMutation((paymentCard: Cards.CardForm) => addPaymentCardAPI(paymentCard), {
    onSuccess: (data) => {
      queryClient.refetchQueries([REACT_QUERY_KEYS.paymentKeys.PAYMENT_CARDS]);
      onSuccess?.(data);
      Avo.paymentMethodAdded({
        paymentMethod: 'CARD' as Cards.PaymentMethods,
        screen: location.pathname,
      });
    },
    onError: (error) => {
      onError?.(error);
    },
  });
}

export function useDeletePaymentCard(onSuccess?: () => void) {
  const queryClient = useQueryClient();
  return useMutation((cardNumber: string) => deletePaymentCardAPI(cardNumber), {
    onSuccess: () => {
      queryClient.refetchQueries([REACT_QUERY_KEYS.paymentKeys.PAYMENT_CARDS]);
      onSuccess?.();
    },
  });
}

export function useUpdateDefaultPaymentMethod() {
  return useMutation(({ method, id }: { method: Cards.PaymentMethods; id?: string }) => updateDefaultPaymentAPI(method, id));
}

export function useUpdateFailedOrderPaymentCard({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) {
  const config = useConfig();
  const queryClient = useQueryClient();

  // if partner override the method for mutation, use the partner's method
  return useMutation({
    mutationFn: ({ orderId, card }: { orderId: string; card?: Cards.CardForm }) =>
      config?.api?.fixPayment ? config.api.fixPayment(orderId) : addFailedOrderPaymentCardAPI(orderId, card!!),
    onSuccess: (data: { order: Order.Order; user: Customer.Customer; redirectURI?: string }) => {
      if (data.redirectURI || config?.api?.fixPayment) {
        window.location.href = data.redirectURI!!;
      } else {
        if (data.user) queryClient.setQueryData([REACT_QUERY_KEYS.userKeys.FETCH_CUSTOMER], data.user);
        setTimeout(async () => {
          await queryClient.refetchQueries([REACT_QUERY_KEYS.orderKeys.FETCH_ORDER_DETAILS, data.order.id]);
          onSuccess?.();
        }, 2000);
      }
    },
    onError,
  });
}
