import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomerCurrentLocation, setCustomerLocation } from 'redux-stores/slices/authSlice';
import type { AppDispatch } from 'redux-stores/store';
import { notifyErrorObject } from 'wrappers/reporting';

export function useCacheCurrentLocation(isAgent: boolean, isAllday: boolean) {
  const customerCurrentLocation = useSelector(selectCustomerCurrentLocation);
  const dispatch = useDispatch<AppDispatch>();

  const onError = (error: GeolocationPositionError) => {
    notifyErrorObject(error);
  };

  const getCurrentLocation = async () => {
    try {
      if (navigator.geolocation && isAgent) {
        navigator.geolocation.getCurrentPosition((position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          dispatch(setCustomerLocation(pos));
        }, onError);
      }
    } catch (err) {
      notifyErrorObject(err);
    }
  };

  useEffect(() => {
    if (customerCurrentLocation || !isAllday) {
      return;
    }

    const handlePermissionChange = (status: PermissionStatus) => {
      if (status.state === 'granted') {
        getCurrentLocation();
      }
    };

    // Query the current geolocation permission status
    navigator.permissions.query({ name: 'geolocation' }).then((status) => {
      // Initial permission status
      handlePermissionChange(status);

      // Listen for changes in the permission status
      // eslint-disable-next-line no-param-reassign
      status.onchange = () => handlePermissionChange(status);
    });

    getCurrentLocation();
  }, [isAgent, customerCurrentLocation]);

  const isLocationRequired = useMemo(() => Boolean(isAgent && !customerCurrentLocation), [isAgent, customerCurrentLocation]);

  return {
    isLocationMissing: isLocationRequired,
    getCurrentLocation,
  };
}
