const addressKeys = {
  DELETE_CUSTOMER_ADDRESS: 'deleteCustomerAddress',
  GET_ALL_CUSTOMER_ADDRESSES: 'getAllCustomerAddresses',
  GET_ALL_STORE_ADDRESSES: 'getAllStoreAddresses',
  ADD_CUSTOMER_ADDRESS: 'addCustomerAddress',
  EDIT_CUSTOMER_ADDRESS: 'editCustomerAddress',
  CHECK_COVERAGE_ADDRESS: 'checkCoverageAddress',
};

const homeKeys = {
  CAROUSEL_LIST: 'CAROUSEL_LIST',
};

const paymentKeys = {
  PAYMENT_CARDS: 'paymentCards',
};

const pricingKeys = {
  ALL_PRICING: 'ALL_PRICING',
};

const timeslotKeys = {
  PICKUP_TIMESLOT: 'pickUpTimeSlots',
  DROPOFF_TIMESLOT: 'dropOffTimeSlots',
};

const userKeys = {
  FETCH_CUSTOMER: 'fetchCustomer',
  FETCH_CUSTOMER_PREFERENCES: 'fetchCustomerPreferences',
  UPDATE_LANGUAGE: 'updateUserLanguage',
  UPDATE_USER: 'updateUser',
};

const orderKeys = {
  FETCH_ORDER_DETAILS: 'fetchOrderDetails',
  ALL_ORDERS_PAGINATED: 'allOrdersPaginated',
  PAST_ACTIVE_ORDERS: 'pastActiveOrders',
  DRAFT_ORDER: 'draftOrder',
};

const globalKeys = {
  FETCH_GLOBAL_CONFIG: 'globalConfig',
};

export const REACT_QUERY_KEYS = {
  paymentKeys,
  addressKeys,
  homeKeys,
  pricingKeys,
  timeslotKeys,
  userKeys,
  orderKeys,
  globalKeys,
};
