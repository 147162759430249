import type { Communications } from 'api/types/communication.types';
import Page from 'layout/page';
import type { CodeDataForm } from 'pages/login/features/types';
import { useUpdatePhoneNumber } from 'pages/queries/customer.queries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectCustomer } from 'redux-stores/slices/authSlice';
import { lazyWithPreload, withComponentPreloading } from 'utils/bundle';

export const VerifyCodePage = lazyWithPreload(() => import('pages/login/features/verify-code-page'));
export const PhoneNumberForm = lazyWithPreload(() => import('pages/login/features/phone-number-form'));
export const ProfileFormPage = lazyWithPreload(() => import('pages/login/features/profile-form-page'));

interface Props {}

const Profile: React.FC<Props> = () => {
  const customer = useSelector(selectCustomer);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { mutate: editPhoneNumber } = useUpdatePhoneNumber({
    onSuccess: () => {
      navigate('/profile');
    },
    onError: () => {
      toast.dark(t('profilePage.phoneUpdateFailed'));
    },
  });

  const onNewPhoneNumberFormSuccess = (phoneNumber: string, communicationMethod: Communications.Methods) => {
    navigate('/profile/editPhone/confirmOTP', {
      state: {
        phone: phoneNumber,
        communicationMethod,
      },
    });
  };

  const handleUpdatePhone = async ({ code, phone }: CodeDataForm) => {
    editPhoneNumber({
      oldPhone: customer?.phone ?? '',
      tempCode: code,
      phone,
    });
  };

  return (
    <Routes>
      <Route path="editPhone">
        <Route
          index
          element={
            <Page
              hasBack
              route="/profile"
              title={t('profilePage.editPhoneTitle')}
              description={t('profilePage.editPhoneDescription')}
              headerProps={{
                isShowPricingOption: false,
                isShowSupportOption: true,
              }}
            >
              <PhoneNumberForm onSuccess={onNewPhoneNumberFormSuccess} />
            </Page>
          }
        />
        <Route path="confirmOTP" element={<VerifyCodePage onSuccess={handleUpdatePhone} />} />
      </Route>

      <Route index element={<ProfileFormPage />} />
      <Route element={<Navigate to={pathname} />} />
    </Routes>
  );
};

export default withComponentPreloading<Props>({
  components: [VerifyCodePage, PhoneNumberForm, ProfileFormPage],
})(Profile);
