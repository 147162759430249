import type { TimeSlots } from 'api/types/timeSlot.types';

// getting last available timeslot from timeslots
export const getLastAvailableTimeslot = (timeslots: TimeSlots.Timeslot[] = []): TimeSlots.Timeslot | undefined => {
  if (timeslots.length === 0) {
    return undefined;
  }

  const lateAvailableTimeslot = timeslots.find((slot) => slot.slotId === 'LATE' && slot.isAvailable);
  if (lateAvailableTimeslot) {
    return lateAvailableTimeslot;
  }

  const reverseTimeslots = timeslots.slice().reverse();
  return reverseTimeslots.find((slot) => slot.isAvailable);
};
